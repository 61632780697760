import React from 'react';
import phaseOneMarkdown from './phase1.md';
import phaseTwoMarkdown from './phase2.md';
import phaseThreeMarkdown from './phase3.md';
import phaseFourMarkdown from './phase4.md';
import { TabbedPanel } from '@uitk/react';
import {MarkdownFileComponent} from "./PhaseOne";

const panels = [
    {
        header: "Phase 1",
        content: <MarkdownFileComponent mdFileUrl={phaseOneMarkdown} />,
    },
    {
        header: "Phase 2",
        content: <MarkdownFileComponent mdFileUrl={phaseTwoMarkdown} />,
    },
    {
        header: "Phase 3",
        content: <MarkdownFileComponent mdFileUrl={phaseThreeMarkdown} />,
    },
    {
        header: "Phase 4",
        content: <MarkdownFileComponent mdFileUrl={phaseFourMarkdown} />,
    },
];


export const CiamMigrationPage = () => {
    return (
        <TabbedPanel panels={panels} />
    )
}