export const OICoreConfig = {
    sentinel: {
        phaseOne: {
            authority: "https://idx-stage.linkhealth.com/auth/realms/developer-platform",
            userinfo: "https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/userinfo",
            introspectToken: "https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/token/introspect",
            token_endpoint: "https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/token",
            // client_id: "login-demo-token-parity",
            client_id: "login-demo",
            // client_id: "CARMa-s9notal7qvwrrpbgr9vo",
            redirect_uri: window.location.origin,
            post_logout_redirect_uri: window.location.origin,
            automaticSilentRenew: false,
            default_idp: 'ciam'
        },
        phaseTwo: {
            authority: "https://idx-stage.linkhealth.com/auth/realms/developer-platform",
            userinfo: "https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/userinfo",
            introspectToken: "https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/token/introspect",
            token_endpoint: "https://idx-stage.linkhealth.com/auth/realms/developer-platform/protocol/openid-connect/token",
            // client_id: "login-demo-token-parity",
            client_id: "login-demo",
            // client_id: "CARMa-s9notal7qvwrrpbgr9vo",
            redirect_uri: window.location.origin,
            post_logout_redirect_uri: window.location.origin,
            automaticSilentRenew: false,
            default_idp: 'ciam'
        }
    },
    guardian: {
        guardianUserProfile: "https://gateway.optuminsightplatform.com/pc-profilev2/profile",
        siteId: "808",
        tenantId: "PC"
    }
}