import React from 'react';
import {Button} from "@uitk/react";
import {useAuth} from "react-oidc-context";
import {OIDCContext} from "../../context";

export const HomePage = () => {
    const auth = useAuth();
    const oidcContext = React.useContext(OIDCContext);

    const tokenParityLogin = async () => {
        oidcContext.togglePhase("phase1");
        await auth.signinRedirect({
            extraQueryParams: {originating_client_id: "CARMa-s9notal7qvwrrpbgr9vo"}
        });
    }

    const changeBrandingLogin = async () => {
        oidcContext.togglePhase("phase2");
        await auth.signinRedirect({
            extraQueryParams: {originating_client_id: "CARMa-s9notal7qvwrrpbgr9vo"}
        });
    }

    return (
        <div style={{margin: '0 auto', height: '50vh', maxWidth: "75vh"}}>
            <h2>Logins Flows</h2>
            <div style={{marginBottom: ".5rem"}}>
                <Button block
                        onPress={tokenParityLogin}
                >
                    Ciam - Sentinel Token Parity Login
                </Button>
            </div>
            <div style={{marginBottom: ".5rem"}}>
                <Button block
                        style={{marginBottom: "2rem"}}
                        onPress={changeBrandingLogin}
                >
                    Sentinel - Username-Email pass to CIAM (Change Branding)
                </Button>
            </div>
            <div style={{marginBottom: ".5rem"}}>
                <Button block
                        disabled
                        style={{marginBottom: "2rem"}}
                >
                    Sentinel - Username-Email pass to CIAM (Optum Branding)
                </Button>
            </div>
            <div style={{marginBottom: ".5rem"}}>
                <Button block
                        disabled
                        style={{marginBottom: "2rem"}}
                >
                    Sentinel - Migrate users to OHID
                </Button>
            </div>
        </div>
    )
}