import React from 'react';
import {OICoreConfig} from "../../oicore-config";
import axios from "axios";
import {useAuth} from "react-oidc-context";
import {TextInput, LoadingIndicator, Notification, Tag, FormControl, Label} from "@uitk/react";
import * as _ from "lodash";
import {Person} from "@uitk/react-icons";
import styled from "styled-components";

const Layout = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  .uitk-tag {
    margin: .25rem .25rem;
    padding: 8px;
  }
`;

export const UserProfilePage = () => {
    const [guardianInfo, setGuardianInfo] = React.useState<any>();
    const [guardianUrl, setGuardianUrl] = React.useState<string>();
    const [isGuardianLoading, setIsGuardianLoading] = React.useState(false);
    const [isGuardianError, setIsGuardianError] = React.useState(false);
    const [guardianErrorMessage, setGuardianErrorMessage] = React.useState<string>();
    const {user} = useAuth();


    React.useEffect(() => {
        alert('what?')
        const loadUserProfile = async () => {
            setIsGuardianLoading(true);
            setIsGuardianError(false);
            try {
                let url = `${OICoreConfig.guardian.guardianUserProfile}`;
                url += `?linkUuid=${user?.profile?.sub}`;
                url += `&tenantId=${OICoreConfig.guardian.tenantId}`;
                url += `&siteId=${OICoreConfig.guardian.siteId}`;
                url += `&accountStatusCode=A`;
                setGuardianUrl(url);
                const response = await axios.get(url, {
                    headers: {
                        'Authorization': `Bearer ${user?.access_token}`
                    }
                });
                setGuardianInfo(response?.data);
            } catch (error: any) {
                setIsGuardianError(true);
                setGuardianErrorMessage(error.message);
            }
            setIsGuardianLoading(false);
        }
        if (user?.access_token && user?.profile?.sub) {
            loadUserProfile();
        }
    }, [user?.access_token, user?.profile?.sub]);


    const getTags = () => {
        if (!guardianInfo) {
            return null;
        }
        let permissions: any[] = [];
        for (const account of guardianInfo.userAccounts) {
            for (const organization of account.organization?.organizations) {
                if (organization?.permissions) {
                    permissions = [...permissions, ...organization.permissions]
                }
            }
        }
        permissions = _.uniq(permissions);
        return (
            <Layout>
                {
                    permissions?.map(permission => <Tag variant="info-alt"
                                                        label={permission} className={"permission"}
                                                        icon={<Person fill={"#fff"} size={"m"}/>}/>)}
            </Layout>
        )
    }

    return (
        <div className={"grid"}>
            <h3>User Profile</h3>
            <FormControl id={"guardian-url"} disabled>
                <Label>User Profile API URL</Label>
                <TextInput value={guardianUrl}/>
            </FormControl>
            <div className={"row"}>
                <div className={"col"}>
                    <LoadingIndicator size={"m"} loading={isGuardianLoading}/>
                    {!isGuardianLoading && !isGuardianError &&
                    <div>
                        {getTags()}
                    </div>
                    }
                    {!isGuardianLoading && isGuardianError &&
                    <Notification className="mv-m" variant={"error"} id={"notification-error"}>
                        {guardianErrorMessage}
                    </Notification>
                    }
                </div>
            </div>
            <div className={"row"}>
                <div className={"col"}>
                    {!isGuardianLoading && !isGuardianError &&
                    <div>
                        <pre style={{
                            fontSize: '.8rem',
                            height: '50vh',
                            overflow: 'scroll',
                            backgroundColor: 'rgb(246, 246, 246)',
                            color: 'black',
                            padding: '5vh'
                        }}>
                                    {JSON.stringify(guardianInfo, null, 2)}
                                </pre>
                    </div>
                    }
                    {!isGuardianLoading && isGuardianError &&
                    <Notification className="mv-m" variant={"error"} id={"notification-error"}>
                        {guardianErrorMessage}
                    </Notification>
                    }
                </div>
            </div>
        </div>
    )
}