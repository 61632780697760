import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import "@uitk/themes/optum/fonts.css";
import AppWrapper from "./components/App/App.component";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(
    <AppWrapper />
);

