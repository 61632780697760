import React from "react";
import {createGlobalStyle} from "styled-components"
import {Toolkit, Notification} from "@uitk/react";

import {
    Routes,
    Route,
    BrowserRouter, useLocation,
} from "react-router-dom";
import "@uitk/themes/optum/fonts.css";
import {
    HomePage,
    UserInfoPage,
    TokenPage,
    UserProfilePage,
    AuthPage,
    LoginFlowPage,
    NotFoundPage,
    CiamMigrationPage, IDTokenPage
} from "../../pages";
import {NavComponent} from "../Nav";
import {useSearchParams, Navigate} from "react-router-dom";
import {AuthProvider, useAuth} from "react-oidc-context";
import {ErrorPageComponent, LoadingIndicatorComponent} from "../Indicators";
import {optum} from "@uitk/themes";
import jwtDecode from "jwt-decode";
import {OICoreConfig} from "../../oicore-config";
import {OIDCContext, OIDCProvider} from "../../context";

const GlobalStyles = createGlobalStyle`
    html, body {
        margin: 0;
        padding: 0;
    }
    html, body, #react-root {
        height: 100%;
    }
    .main {
        margin .5rem;
    },
`;

const hideNavOnRoutes = ["/auth"];

export const App: React.FC = () => {
    // eslint-disable-next-line
    let [searchParams, setSearchParams] = useSearchParams();
    const {
        activeNavigator,
        error,
        isLoading,
        user,
        isAuthenticated} = useAuth();
    const {pathname} = useLocation();

    React.useEffect(() => {
        if (user?.access_token) {
            setSearchParams({});
        }
        // eslint-disable-next-line
    }, [user?.access_token]);

    const isOHIDAuthenticated = () => {
        if (user) {
            const token: any = jwtDecode(user?.access_token);
            return token?.identity_provider === "optum-id";
        } else {
            return false;
        }
    }

    if (isLoading) {
        return <LoadingIndicatorComponent message={"Authenticating"}/>;
    }

    if (error) {
        return <ErrorPageComponent/>;
    }

    return (
        <>
            <GlobalStyles/>
            <Toolkit appId="@link/example-pkce" grid spacing theme={optum}>
                {!hideNavOnRoutes.includes(pathname) && <NavComponent/>}
                {activeNavigator === "signinSilent" &&
                <LoadingIndicatorComponent message={"Signing you in"}/>
                }
                {activeNavigator === "signoutRedirect" &&
                <LoadingIndicatorComponent message={"Logging out"}/>
                }
                {isAuthenticated && isOHIDAuthenticated() &&
                    <Notification variant={"success"} id={"notification-info"} dismissable>
                        One Healthcare user migration complete!
                    </Notification>
                }
                <div className={"main"}>
                    <Routes>
                        <Route path="/" element={<HomePage/>}/>
                        <Route path={"/auth"} element={<AuthPage />} />
                        <Route path="/userinfo" element={<UserInfoPage/>}/>
                        <Route path="/token" element={<TokenPage/>}/>
                        <Route path={"/idtoken"} element={<IDTokenPage />} />
                        <Route path="/userprofile" element={<UserProfilePage/>}/>
                        <Route path="/loginflows" element={<LoginFlowPage />} />
                        <Route path={"/ciam-migration"} element={<CiamMigrationPage />} />
                        <Route path={"/404"} element={<NotFoundPage />} />
                        <Route path={"*"} element={<Navigate to={"/404"} />} />
                    </Routes>
                </div>
            </Toolkit>
        </>
    );
};

const ApplicationWrapper: React.FC = () => {
    const context = React.useContext(OIDCContext);

    return (
        <div>
            {context.phase === "phase1" ?
                <AuthProvider {...OICoreConfig.sentinel.phaseOne}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </AuthProvider>:
                <AuthProvider {...OICoreConfig.sentinel.phaseTwo}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </AuthProvider>
            }
        </div>
    )
}


const AppWrapper: React.FC = () => {
    return (
        <OIDCProvider>
            <ApplicationWrapper />
        </OIDCProvider>
    )
}



export default AppWrapper;