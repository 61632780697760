import React from 'react';
import SubLookUpImg from './sub_lookup_auth.png';

export const LoginFlowPage = () => {

    return (
        <div>
            <h4>User Migration to One Healthcare ID (OHID)</h4>
            <p>Pictured below is a migration strategy for migrating your users to OHID.</p>
            <img alt={"User migration flow"} src={SubLookUpImg} />
        </div>
    )
}