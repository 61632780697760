import React from 'react';
import {anchorProperties, getNativeProps, Header} from "@uitk/react";
import Logo from "../../svg/Logo.svg";
import {useLocation, NavLink} from "react-router-dom";
import {useAuth} from "react-oidc-context";
import {SigninRedirectArgs} from "oidc-client-ts";
import {OICoreConfig} from "../../oicore-config";

const RoutableLink: React.FC<any> = (item) => {
    const {url, children, onClick} = item;
    const anchorProps = getNativeProps(item, anchorProperties);
    if (url) {
        return (
            <NavLink to={url} {...anchorProps}>
                {children}
            </NavLink>
        );
    } else {
        return (
            <a style={{cursor: 'pointer'}} onClick={onClick} {...anchorProps}>
                {children}
            </a>
        )
    }
};

function useCurrentRoute() {
    const {pathname: route} = useLocation();
    return route;
}

export const NavComponent = () => {
    const auth = useAuth();
    let links: any[];


    const logout = async () => {
        let logoutUrl = `${OICoreConfig.sentinel.phaseOne.authority}/protocol/openid-connect/logout`;
        logoutUrl += `?post_logout_redirect_uri=${OICoreConfig.sentinel.phaseOne.redirect_uri}`;
        logoutUrl += `&client_id=${OICoreConfig.sentinel.phaseOne.client_id}`;
        window.location.assign(logoutUrl);
    }

    const onHandleLogin = async (idp: null | string) => {
        if (idp) {
            const signInRedirectArgs: SigninRedirectArgs = {
                extraQueryParams: {
                    kc_idp_hint: idp
                }
            }
            await auth.signinRedirect(signInRedirectArgs)
        } else {
            await auth.signinRedirect({
                redirect_uri: window.location.origin
            });
        }
    }



    if (auth?.isAuthenticated) {
        links = [
            {label: "Logout", onClick: () => logout()},
            {label: "CIAM Migration", url: "/ciam-migration"},
            {label: "OHID User Migration", url: "/loginflows"},
            {label: "User Info", url: "/userinfo"},
            {label: "ID Token", url: "/idtoken"},
            {label: "Token Info", url: "/token"},
            {label: "Home", url: "/"},
        ]
    } else {
        links = [
            {label: "CIAM Migration", url: "/ciam-migration"},
            {label: "CIAM Login", onClick: () => onHandleLogin("ciam")},
            {label: "OHID Login", onClick: () => onHandleLogin("optum-id")},
            {label: "MSID Login", onClick: () => onHandleLogin("uhg-ms-id")},
            {label: "Sign In", onClick: () => onHandleLogin(null)},
        ]
    }

    return (
        <>
            <Header
                logoContent={
                    <>
                        <img src={Logo} alt="logo"/>
                    </>
                }
                globalNavigation={{
                    linkAs: RoutableLink,
                    links: links
                }}
                useLocation={useCurrentRoute}
                skipLink={{id: "main"}}
                productName="OI Core Sentinel - CIAM Integration Application"
                productLink="/"
            />
        </>
    )
}