import React from "react";
import ReactMarkdown from "react-markdown";
import remarkGFM from "remark-gfm";

export const MarkdownFileComponent = ({mdFileUrl}:{mdFileUrl: string}) => {
    const [markdownText, setMarkdownText] = React.useState<string>("");

    React.useEffect(() => {
        fetch(mdFileUrl).then((response) => response.text())
            .then((text) => {
                setMarkdownText(text);
            });
    }, [mdFileUrl]);

    return (
        <div style={{ border: '1px solid black', padding: "5rem"}}>
            <div>
                <ReactMarkdown rehypePlugins={[remarkGFM]}
                               components={{img:({node,...props})=><img alt={props?.src}
                                                                        style={{maxWidth:'100%'}}{...props}/>}}
                >
                    {markdownText}
                </ReactMarkdown>
            </div>
        </div>
    )
}