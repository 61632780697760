import React from 'react';
import {useAuth} from "react-oidc-context";
import axios from "axios";
import {OICoreConfig} from "../../oicore-config";

export const UserInfoPage = () => {
    const [token, setToken] = React.useState();
    const {user} = useAuth();

    React.useEffect(() => {
        const getData = async (accessToken: any) => {
            const response = await axios.get(OICoreConfig.sentinel.phaseOne.userinfo, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });
            setToken(response.data);
        }
        if (user?.access_token) {
            getData(user?.access_token)
        }
    }, [user?.access_token]);


    return (
        <div>
            <h1>User Info Page</h1>
            <pre style={{
                fontSize: '.9rem',
                height: '60vh',
                overflow: 'scroll',
                backgroundColor: 'rgb(246, 246, 246)',
                color: 'black',
                padding: '1vh'
            }}>
                {JSON.stringify(token, null, 2)}
            </pre>
        </div>
    )
}