import React from 'react';
import {useAuth} from "react-oidc-context";
import jwtDecode from "jwt-decode";
import {Button, Notification} from "@uitk/react";


export const TokenPage = () => {
    const auth = useAuth();
    const [displayCopySuccess, setDisplayCopySuccess] = React.useState(false);

    const onCopyToken = () => {
       navigator.clipboard.writeText(String(auth?.user?.access_token));
       setDisplayCopySuccess(true);
       setTimeout(() => {
           setDisplayCopySuccess(false);
       }, 3000);
    }

    return (
        <div>
            {displayCopySuccess &&
            <Notification

                className="mv-m"
                variant={"success"}
                id={"notification-success"}
            >
                Access Token Copied to Clipboard
            </Notification>
            }
            <h1>Decoded Access Token</h1>
            <pre style={{
                fontSize: '.9rem',
                height: '50vh',
                overflow: 'scroll',
                backgroundColor: 'rgb(246, 246, 246)',
                color: 'black',
                padding: '1vh'
            }}>
                {JSON.stringify(jwtDecode(auth?.user?.access_token as string), null, 1)}
            </pre>
            <h1>Encoded Access Token</h1>
            <pre style={{
                wordBreak: "break-all",
                fontSize: '.9rem',
                overflow: 'scroll',
                backgroundColor: 'rgb(246, 246, 246)',
                color: 'black',
                padding: '1vh'
            }}>
                {auth?.user?.access_token}
            </pre>
            <Button onPress={onCopyToken}>Copy Access Token</Button>
        </div>
    )
}