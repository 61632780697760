import React from 'react';

type OIDCConfig = {
    phase: string;
    togglePhase: (name: string) => void;
}

let OIDCContext = React.createContext<OIDCConfig>({
    phase: "phase1",
    togglePhase: (name: string) => {}
});

interface BaseLayoutProps {
    children?: React.ReactNode;
}

const OIDCProvider: React.FC<BaseLayoutProps> = ({children}) => {
    const [phase, setPhase]
        = React.useState<string>("phase2")

    const togglePhase = (configName: string) => {
       setPhase(configName);
    }

    return (
        <OIDCContext.Provider value={{phase, togglePhase}}>
            {children}
        </OIDCContext.Provider>
    )
}

export {OIDCProvider, OIDCContext};