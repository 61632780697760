import React from 'react';
import {useAuth} from "react-oidc-context";
import {Button, Notification} from "@uitk/react";
import jwtDecode from "jwt-decode";

export const IDTokenPage = () => {
    const auth = useAuth();
    const [displayCopySuccess, setDisplayCopySuccess] = React.useState(false);


    const onCopyToken = () => {
        navigator.clipboard.writeText(String(auth?.user?.access_token));
        setDisplayCopySuccess(true);
        setTimeout(() => {
            setDisplayCopySuccess(false);
        }, 3000);
    }

    return (
        <div>
            {displayCopySuccess &&
                <Notification
                    className="mv-m"
                    variant={"success"}
                    id={"notification-success"}
                >
                    ID Token Copied to Clipboard
                </Notification>
            }
            <h1>Decoded ID Token</h1>
            <pre style={{
                fontSize: '.9rem',
                height: '50vh',
                overflow: 'scroll',
                backgroundColor: 'rgb(246, 246, 246)',
                color: 'black',
                padding: '1vh'
            }}>
                {JSON.stringify(jwtDecode(auth?.user?.id_token as string), null, 1)}
            </pre>
            <h1>Encoded ID Token</h1>
            <pre style={{
                wordBreak: "break-all",
                width: "100%",
                fontSize: '.9rem',
                overflow: 'scroll',
                backgroundColor: 'rgb(246, 246, 246)',
                color: 'black',
                padding: '1vh'
            }}>
                {auth?.user?.id_token}
            </pre>
            <Button onPress={onCopyToken}>Copy Access Token</Button>
        </div>
    )
}