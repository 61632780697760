import React from 'react';

type LoadingIndicatorProps = {
    message: string
}

export const LoadingIndicatorComponent = ({message}: LoadingIndicatorProps) => {

    return (
        <div>
            <h1>{message}</h1>
        </div>
    )
}