import React from 'react';
import {useAuth} from "react-oidc-context";

export const AuthPage = () => {
    const { signinRedirect} = useAuth();

    React.useEffect(() => {
        const login = async () => {
            await signinRedirect({
                redirect_uri: window.location.origin
            });
        }

        login();
    },[signinRedirect]);

    return (
        <div>
            AUTH PAGE
        </div>
    )
}